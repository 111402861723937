// 导入axios实例
import httpRequest from '@/utils/request'

// 获取用户信息
export function userLogin(data) {
    return httpRequest({
        url: '/user/login',
        method: 'post',
        data,
    })
}

export function productsList(data) {
    return httpRequest({
        url: '/products/list',
        method: 'get',
        data
    })
}

export function productsCate(data) {
    return httpRequest({
        url: '/products/cate',
        method: 'get',
        data
    })
}

export function getUserInfo(data) {
    return httpRequest({
        url: '/user/info',
        method: 'get',
        data
    })
}

export function userPwd(data) {
    return httpRequest({
        url: '/user/pwd',
        method: 'post',
        data
    })
}

export function userUpdate(data) {
    return httpRequest({
        url: '/user/update',
        method: 'post',
        data
    })
}

export function addressSave(data) {
    return httpRequest({
        url: '/user/address_save',
        method: 'post',
        data
    })
}

export function addressDel(data) {
    return httpRequest({
        url: '/user/address_del',
        method: 'post',
        data
    })
}

//运费预估
export function logistFee(params) {
    return httpRequest({
        url: '/logist/fee',
        method: 'get',
        params
    })
}

export function transactionExport(data) {
    return httpRequest({
        url: '/user/transaction_export',
        method: 'post',
        data
    })
}

// 请求地址
export function userAddress(params) {
    return httpRequest({
        url: '/user/address',
        method: 'get',
        params
    })
}

export function statistics(params) {
    return httpRequest({
        url: '/user/statistics',
        method: 'get',
        params
    })
}


export function distributionList(params) {
    return httpRequest({
        url: '/user/distribution_list',
        method: 'get',
        params
    })
}
// 提现


export function comCash(data) {
    return httpRequest({
        url: '/com/cash',
        method: 'post',
        data
    })
}

export function uploadImage(data) {
    return httpRequest({
        url: '/upload/image',
        method: 'post',
        data
    })
}


export function recreateWrmProducts(data) {
    return httpRequest({
        url: '/user/recreate_wrm_products',
        method: 'post',
        data
    })
}


export function accountList(params) {
    return httpRequest({
        url: '/user/account_list',
        method: 'get',
        params
    })
}

export function authmenu(params) {
    return httpRequest({
        url: '/user/authmenu',
        method: 'get',
        params
    })
}

export function subAccount(params) {
    return httpRequest({
        url: '/user/sub_account',
        method: 'get',
        params
    })
}

export function resetpwd(data) {
    return httpRequest({
        url: '/user/resetpwd',
        method: 'post',
        data
    })
}

export function addShareUser(data) {
    return httpRequest({
        url: 'user/add_share_user',
        method: 'post',
        data
    })
}

export function getShareUser(params) {
    return httpRequest({
        url: 'user/get_share_user',
        method: 'get',
        params
    })
}

export function downloadRecord(data) {
    return httpRequest({
        url: 'user/download_record',
        method: 'post',
        data
    })
}


export function recharge(data) {
    return httpRequest({
        url: 'user/recharge',
        method: 'post',
        data
    })
}